<template>
    <div :class="singleEventClass" v-if="selectedEvent">
        <div class="page__content">
            <SingleCta :slug="$route.params.eventId"/>
            <SingleHeader 
                :series="selectedEvent.series"
                :title="selectedEvent.title"
                :day="selectedEvent.day"
                :time="selectedEvent.time"
                :date="selectedEvent.date"
                :where="selectedEvent.where"
                :people="selectedEvent.content.people"
            />
            <PartnerList :partners="selectedEvent.content.partners"/>
            <GalleryCarousel :images="selectedEvent.content.gallery" />
            <TheProgram 
                :people-list="selectedEvent.content.people"
                :program="selectedEvent.content.program"
                :description="selectedEvent.content.description"
                :ad="selectedEvent.content.adProgram"
            />  
            <DropdownList
                :people="selectedEvent.content.people"
                :dropdowns="selectedEvent.content.dropDowns" 
            />
            <VideoBlock 
                :vidId="selectedEvent.content.videoBlock.video"
                :topBtn="selectedEvent.content.videoBlock.topButton"
                :bottomBtn="selectedEvent.content.videoBlock.bottomButton"
            />
            <AdBox
                :img="selectedEvent.content.adBottomPage.img"
                :url="selectedEvent.content.adBottomPage.url"
            />
            <FooterSponsors />
        </div>
    </div>
</template>
<script>
    import SingleCta from './SingleCta.vue';
    import SingleHeader from './SingleHeader.vue';
    import PartnerList from './PartnerList.vue';
    import GalleryCarousel from './GalleryCarousel.vue';
    import TheProgram from './TheProgram.vue';
    import DropdownList from './DropdownList.vue';
    import VideoBlock from './VideoBlock.vue';
    import AdBox from './AdBox.vue';
    import FooterSponsors from './FooterSponsors.vue';
    
    export default {
        components: {
            SingleCta,
            SingleHeader,
            PartnerList,
            GalleryCarousel,
            TheProgram,
            DropdownList,
            VideoBlock,
            AdBox,
            FooterSponsors
        },
        // data() {
        //     return {
        //         singleEvent: this.selectedEvent,
        //         title: this.selectedEvent.title,
        //         series: this.selectedEvent.serie,
        //         day: this.selectedEvent.day,
        //         time: this.selectedEvent.time,
        //         date: this.selectedEvent.date,
        //         where: '',
        //         dropDowns: [],
        //         gallery: [],
        //         partners: [],
        //         people: [],
        //         program: '',
        //         description: '',
        //         adProgram: {},
        //         txtColor: '',
        //         bgColor: ''
        //     }
        // },
        // methods: {
        //     setData: function (thisEvent) {
        //         this.title = thisEvent.title;
        //         this.series = thisEvent.series;
        //         this.day = thisEvent.day;
        //         this.time= thisEvent.time;
        //         this.date= thisEvent.date;
        //         this.where = thisEvent.where;
        //         this.dropDowns = thisEvent.content.dropDowns;
        //         this.gallery = thisEvent.content.gallery;
        //         this.partners = thisEvent.content.partners;
        //         this.people = thisEvent.content.people;
        //         this.program = thisEvent.content.program;
        //         this.description = thisEvent.content.description;
        //         this.adProgram = thisEvent.content.adProgram;
        //         this.txtColor = thisEvent.txtColor;
        //         this.bgColor = thisEvent.bgColor;
        //     }
        // },
        computed: {
            singleEventClass(){
                return 'page single-event ' + 'single-event--' + this.selectedEvent.txtColor + ' ' + 'single-event--' + this.selectedEvent.bgColor
            },
            selectedEvent(){
                //return this.$store.state.events.find(event => event.id === this.$route.params.eventId)
                // return this.$store.state.events.find(event => event.id == this.$route.params.eventId)
                return this.$store.state.events.find(event => event.slug == this.$route.params.eventId)
            },
            dropDownListAndPeople(){
                return false;
            }
        },
        created(){
            // // console.log(this.selectedEvent)
            // // this.$store.dispatch('getAndSetData');
            // // this.setData(this.selectedEvent)
            // this.setData(this.selectedEvent)
            this.$store.dispatch('getAndSetData');
        }
        
    }
</script>
<style scoped lang="scss">

    $yellow: rgb(255,255,0);
    $purple: rgb(86,40,133);
    $darkBlue: rgb(0,110,255);
    $lightBlue: rgb(0,200,255);
    $green: rgb(0,100,55);
    $pink: rgb(255,195,255);

    .single-event--txt-purple {
        color: $purple;
    }
    .single-event--txt-yellow {
        color: $yellow;
    }
    .single-event--txt-pink {
        color: $pink;
    }
    .single-event--txt-light-blue {
        color: $lightBlue;
    }
    .single-event--txt-dark-blue {
        color: $darkBlue;
    }
    .single-event--txt-green {
        color: $green;
    }
    .single-event--txt-black {
        color: black;
    }
    .single-event--txt-white {
        color: white;
    }
    .single-event--bg-purple {
        background-color: $purple;
    }
    .single-event--bg-yellow {
        background-color: $yellow;
    }
    .single-event--bg-pink {
        background-color: $pink;
    }
    .single-event--bg-light-blue {
        background-color: $lightBlue;
    }
    .single-event--bg-dark-blue {
        background-color: $darkBlue;
    }
    .single-event--bg-green {
        background-color: $green;
    }
    .single-event--bg-black {
        background-color: black;
    }
    .single-event--bg-white {
        background-color: white;
    }

    .component {

    }

    .single-event {
        @media print {
            color: black;
        }
    }

</style>