<template>
    <div class="event-filter">
        <div class="event-filter__wrapper">
            <span class="event-filter__title">Filtres: </span>
            <div class="filter-select-wrapper filter-select-wrapper--month">
                <div class="fake-dropdown">
                    <span class="fake-dropdown__label">{{ currentDate }}</span>
                    <span class="fake-dropdown__arrow">↓</span>
                </div>
                <select name="events" id="date-filter" v-model="selectedDate">
                    <option value="all" selected>Date</option>
                    <option v-for="date in dates" :key="date.id" :value="date.id">{{ date.nice }}</option>
                </select>
            </div>
            <div class="filter-select-wrapper filter-select-wrapper--serie">
                <div class="fake-dropdown">
                    <span class="fake-dropdown__label">{{ currentSerie }}</span>
                    <span class="fake-dropdown__arrow">↓</span>
                </div>
                <select name="series" id="serie-filter" v-model="selectedSerie">
                    <option value="all" selected>Séries</option>
                        <option 
                        v-for="serie in series" 
                        :value="serie.name" 
                        :key="serie.slug"
                        :data-slug="serie.slug"
                        >{{ serie.name }}</option>
                </select>
            </div>
            <!-- <div class="event-filter__info">
                <span class="event-filter__filter">Filtre:</span>
                <span class="event-filter__current-filter">{{ currentFilter }}</span>
                <span class="event-filter__icon"><span></span></span>
            </div> -->
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        data() {
            return {
                // months: [
                //     'août',
                //     'septembre',
                //     'octobre',
                //     'novembre',
                //     'décembre',
                //     'janvier', 
                //     'février', 
                //     'mars', 
                //     'avril',
                //     'mai',
                //     'juin',
                //     'juillet'
                // ],
                series: this.$store.state.series,
                dates: this.$store.state.dates,
            }
        },
        // methods: {
        //     // filter(e){
        //     //     //console.log('filter')
        //     //     const filterValue = e.target.value;
        //     //     //console.log(filterValue);
        //     //     this.$store.commit('filteringEvents', {
        //     //         filter: filterValue
        //     //     });
        //     // }
        //     triggerOpenFilter(){
        //         document.getElementById('date-filter').focus();
        //         console.log('fuck off');
        //     }
        // },
        computed: {
            currentDate: function () {
                //return this.$store.state.currentDate === 'all' ? 'Date' : this.$store.state.currentDate;
                return this.$store.state.currentDate === 'all' ? 'Date' : moment(this.$store.state.currentDate, 'DD/MM/YYYY').locale('fr').format('Do MMMM YYYY');
            },

            currentSerie: function () {
                return this.$store.state.currentSerie === 'all' ? 'Série' : this.$store.state.currentSerie;
            },

            selectedDate: {
                get () {
                    return this.$store.state.currentDate
                },
                set (e) {
                    this.$store.commit('filteringEvents', {
                        date: e,
                        serie: this.$store.state.currentSerie
                    });
                }
            },

            selectedSerie: {
                get () {
                    return this.$store.state.currentSerie
                },
                set (e) {
                    this.$store.commit('filteringEvents', {
                        date: this.$store.state.currentDate,
                        serie: e
                    });
                }
            }
        }
        
    };
</script>
<style scoped lang="scss">
    .event-filter {
        background-color: #998B69;
        z-index: 100;
        overflow: hidden;
        position: fixed;
        top: 80px;
        width: 100%;
        z-index: 9;

        >div {
            position: relative;
            max-width: 1200px;
            padding: 0 20px;
            margin: 0 auto;
            height: 50px;
            font-size: 1.2rem;
        }
    }

    .event-filter__wrapper {
        display: flex;
        align-items: center;
    }
    
    .event-filter__title {
        font-family: "monotype grotesque";
        color:black;
    }
    
    .event-filter__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #998B69;
        padding: 0 20px;
        pointer-events: none;
        box-sizing: border-box;
        cursor: pointer;

        > span {
            flex: 1;
            display: flex;

            &:first-child {
                justify-content: flex-start;
            }

            &:last-child {
                justify-content: flex-end;
            }
        }
    }

    .event-filter__title {
        margin-right: 20px;
    }

    .filter-select-wrapper {
        position: relative;
        flex: 1 1 100%;
        height: 100%;
        color:black;
        //border-left: 1px solid #979797;
        //border-right: 1px solid #979797;
    }

    .filter-select-wrapper--month {
        //margin-right: 10px;
        // border-left: 1px solid black;
        // border-right: 1px solid black;
    }

    .filter-select-wrapper--serie {
        margin-left: -1px;
    }

    .fake-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        font-size: 20px;
        background-color: #998B69;
        pointer-events: none;
    }

    .fake-dropdown__label {
        white-space: nowrap;
        flex: 0 1 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .fake-dropdown__arrow {
        font-family: "monotype grotesque";
        padding-left: 10px;
    }

    select {
        height: 100%;
        width: 100%;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .event-filter__filter {
        font-family: 'monotype grotesque';
        font-size: 1.5px;
        font-size: 1.5rem;
        font-size: min(max(1.5rem, 2vw), 2rem);
        font-size: clamp(1.5rem, 2vw, 2rem);
    }

    .event-filter__current-filter {
        font-family: 'monotype grotesque';
        font-size: 1.5px;
        font-size: 1.5rem;
        font-size: min(max(1.5rem, 2vw), 2rem);
        font-size: clamp(1.5rem, 2vw, 2rem);
        justify-content: center;
    }

    .event-filter__icon {
        padding-right: 5px;
        
        > span {
            position: relative;
            color:black;
            display: block;
            width: 20px;
            height: 1px;
            background-color: currentColor;

            &:before,
            &:after {
                content: "";
                display: block;
                height: 1px;
                position: absolute;
                background-color: currentColor;
            }

            &:before {
                top: -7px;
                left: -5px;
                width: 30px;
            }

            &:after {
                bottom: -7px;
                left: 4px;
                width: 13px;
            }            
        }

    }
</style>
