<template>
    <div class="loading-screen">
        <div>
           <!--  <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.31 496.14">
                <g>
                    <path d="M-.15,63.83C25.59,25.29,72.76,6.06,123.91,53.19L266.15-.07V42.55l-147,53.76C59.84,42.06,26,73.48-.15,121.67V63.83Z" transform="translate(0.15 0.07)"/>
                    <path d="M-.15,361.64c44.32-20.29,88.63-34.52,133-34.52,44.72,0,89,14.23,133.35,34.52v43.11c-44.32-21.34-88.63-36.56-133.35-36.56-44.32,0-88.63,15.22-133,36.56V361.64Z" transform="translate(0.15 0.07)"/>
                    <path d="M-.15,462c44.32,20.28,88.63,34,133,34,44.72,0,89-13.75,133.35-34V418.42C221.83,439.78,177.52,455,132.8,455c-44.32,0-88.63-15.21-133-36.57V462Z" transform="translate(0.15 0.07)"/>
                    <path d="M-.15,218.55C34.1,281,86.47,277.45,121.9,238.35c35.47-39.52,95.51-54.74,144.25,1.55v56.79C214.56,212,163,246,130.35,282S28.44,314.44-.15,276.4V218.55Z" transform="translate(0.15 0.07)"/>
                    <polygon points="0 183.72 266.31 183.72 266.31 179.96 0 179.96 0 183.72 0 183.72"/>
                    <polygon points="0 145.67 266.31 145.67 266.31 141.91 0 141.91 0 145.67 0 145.67"/>
                    <path d="M28.19,155.69A10.83,10.83,0,0,0,19.48,152C14,152,8.34,155.79,8.34,163.52c0,5.86,3.93,10.27,10.74,10.27a15.57,15.57,0,0,0,4.82-.66,13.14,13.14,0,0,0,.14-2.57,53.12,53.12,0,0,0-.32-6.34,21.83,21.83,0,0,0,3.27.19,24,24,0,0,0,3.59-.19,38.52,38.52,0,0,0-.36,6.19c0,1.54.05,3.5.13,4.05a51.65,51.65,0,0,1-11.8,1.61c-10.17,0-17.46-4.82-17.46-12.32,0-9.35,7.91-14,18.3-14A24.38,24.38,0,0,1,30,151.93a26.23,26.23,0,0,0-1.24,3.76Z" transform="translate(0.15 0.07)"/>
                    <path d="M57.72,160.39c0-4.12-.09-6.95-.35-10.19,2.61,0,5.26.19,7.87.19,4.33,0,8.05-.08,9.24-.19a7.1,7.1,0,0,0-.18,1.55,8.05,8.05,0,0,0,.18,1.65c-3.36-.18-8.67-.44-10-.44-.22,2.69-.22,5.34-.22,8,3.84-.07,6.67-.14,10-.29a7.58,7.58,0,0,0-.22,1.66,10.13,10.13,0,0,0,.22,1.51c-3.36-.22-6.67-.34-10-.34,0,1.55,0,3.13,0,4.71s0,3.13.13,4.61c3.32,0,6.73-.15,10.13-.48A7.73,7.73,0,0,0,74.3,174a6.62,6.62,0,0,0,.18,1.59c-1.59,0-3.67-.19-7.74-.19-7.21,0-8.4.12-9.37.19.26-3.24.35-6.07.35-10.2v-5Z" transform="translate(0.15 0.07)"/>
                    <path d="M101.18,150.2a12.29,12.29,0,0,0,2,.19,12.08,12.08,0,0,0,2-.19c6.37,5.85,12.69,11.67,19.19,17.52,0-5.85,0-15.86-.31-17.52a10.52,10.52,0,0,0,4,0c-.71,10.71-.71,15.61-.71,25.4a9.27,9.27,0,0,0-1.81-.19,7.74,7.74,0,0,0-1.72.19q-9.09-8.94-19.33-17.89c-.08,2.76-.08,5.81-.08,8.57,0,5.89.13,7.95.26,9.32a12.57,12.57,0,0,0-2.08-.19,11.62,11.62,0,0,0-2,.19c.22-1.07.88-10.57.88-18.08,0-3.31-.17-5.55-.22-7.32" transform="translate(0.15 0.07)"/>
                    <path d="M155.79,160.39c0-4.12-.09-6.95-.36-10.19,2.61,0,5.26.19,7.87.19,4.33,0,8-.08,9.24-.19a7.86,7.86,0,0,0-.17,1.55,8.92,8.92,0,0,0,.17,1.65c-3.36-.18-8.66-.44-10-.44-.22,2.69-.22,5.34-.22,8,3.84-.07,6.68-.14,10-.29a7.14,7.14,0,0,0-.23,1.66,9.23,9.23,0,0,0,.23,1.51c-3.36-.22-6.68-.34-10-.34,0,1.55-.05,3.13-.05,4.71s.05,3.13.13,4.61c3.32,0,6.73-.15,10.13-.48a8.54,8.54,0,0,0-.17,1.65,7.29,7.29,0,0,0,.17,1.59c-1.59,0-3.67-.19-7.74-.19-7.2,0-8.4.12-9.37.19.27-3.24.36-6.07.36-10.2v-5Z" transform="translate(0.15 0.07)"/>
                    <path d="M220.16,150.2a10.05,10.05,0,0,0,3.89,0c-2.92,5.78-8.66,17-12.11,25.4a18.64,18.64,0,0,0-1.95-.19,17,17,0,0,0-1.94.19c-2.75-6.3-9.11-19.77-12-25.4,1.36.11,2.69.19,4,.19s2.61-.08,3.89-.19c2,5.48,5.18,12.33,7.87,18.48,3-6.15,6.06-12.77,8.36-18.48" transform="translate(0.15 0.07)"/>
                    <path d="M248.94,160.39c0-4.12-.08-6.95-.35-10.19,2.61,0,5.26.19,7.87.19,4.33,0,8.05-.08,9.24-.19a7.1,7.1,0,0,0-.18,1.55,8.05,8.05,0,0,0,.18,1.65c-3.36-.18-8.67-.44-10-.44-.22,2.69-.22,5.34-.22,8,3.85-.07,6.68-.14,10-.29a7,7,0,0,0-.22,1.66,10.13,10.13,0,0,0,.22,1.51c-3.36-.22-6.68-.34-10-.34,0,1.55,0,3.13,0,4.71s0,3.13.14,4.61c3.31,0,6.72-.15,10.12-.48a7.73,7.73,0,0,0-.18,1.65,6.62,6.62,0,0,0,.18,1.59c-1.59,0-3.67-.19-7.74-.19-7.2,0-8.4.12-9.37.19.27-3.24.35-6.07.35-10.2v-5Z" transform="translate(0.15 0.07)"/>
                </g>
            </svg> -->
            <img src="@/assets/img/logo-2024/osr_logo_noir_baseline.png" alt="">
            <div class="loading-circle"></div>
        </div>
        
    </div>
</template>
<script>
    export default {};
</script>
<style scoped lang="scss">
    .loading-screen {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color:#998B69; 
        width: 100%;
        height: 100vh;
        z-index: 1000;

        > div {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: calc(50% - 100px);
                left: calc(50% - 100px);
                width: 200px;
                height: 200px;
                //background-color: red;
                background: conic-gradient(rgba(255,114,109,0) 0%, rgba(255, 114, 109, 1) 100%);
                //border: 1px solid yellow;
                border-radius: 50%;
                z-index: -1;
                animation: loading 1s linear infinite;
            }

            &:after {
                content: "";
                position: absolute;
                top: calc(50% - 99px);
                left: calc(50% - 99px);
                width: 198px;
                height: 198px;
                //background-color: red;
                background:white; 
                //border: 1px solid yellow;
                border-radius: 50%;
                z-index: -1;
            }
        }
    }

    svg {
        width: 75px;
        fill: black;
    }
    img{
        width:180px;
    }

    @keyframes loading {
        from {transform: rotate(0deg)}
        to {transform: rotate(360deg)}
    }

</style>