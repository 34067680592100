<template>
    <li class="partner">
        <h3 class="partner__title">{{ title }}</h3>
        <img :src="logo" :alt="name">
    </li>
</template>
<script>
    export default {
        props: ['title', 'name', 'logo']
    }
</script>
<style scoped lang="scss">
    .partner {
        box-sizing: border-box;
        flex: 1 1 25%;
        padding: 0 20px;
        text-align: center;

        @media only screen and (max-width: 600px){
            flex: 1 1 50%;
        }
    }

    .partner__title {
        font-family: 'monotype grotesque';
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    img {
        //height: 85px;
        width: 200px;

        .single-event--bg-green &,
        .single-event--bg-black &,
        .single-event--bg-purple &,
        .single-event--bg-dark-blue & {
            @media print {
                filter: invert(1);
            }
        }
    }

</style>
