<template>
    <div>
        <ul class="dropdowns">
            <!-- <DropDown 
                v-for="person in people" 
                :key="person.name" 
                :title="person.name"
                :content="null"
                :ad-img="null"
                :ad-url="null"
                :personBio="person.bio"
                :personRole="person.role"
                :personImg="person.picture"
                type="person-dropdown"
                class="dropdown--person"
            /> -->
            <DropDown 
                v-for="dropdown in dropdowns" 
                :key="dropdown.title" 
                :title="dropdown.title"
                :content="dropdown.content"
                :ad-img="dropdown.ad"
                :ad-url="dropdown.adUrl"
                :personRole="null"
                :image="dropdown.image"
                :type="dropdown.type"
                :people="people"
                :class="'dropdown--' + dropdown.type"
            />
        </ul>
    </div>
</template>
<script>
    import DropDown from './DropDown.vue';
    export default {
        props: [
            'dropdowns',
            'people'
        ],
        components: {
            DropDown
        },
        // computed: {
        //     hasDropdown(){
        //         return this.people != undefined || this.dropdowns != undefined
        //     }
        // },
        // mounted(){
        //     console.log('HAS DROPDOWN', this.hasDropdown)
        //     console.log('PEOPLE : ', this.people)
        //     console.log('DROPDOWN : ', this.dropdowns)
        // }

    }
</script>
<style scoped>
    div {
        display: flex;
        justify-content: center;
        padding: 50px 30px;
    }

    /* ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    } */
    .dropdowns {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1200px;
        margin: 0 -20px;
        padding: 0;
        list-style: none;
    }
</style>
