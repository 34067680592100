<template>
    <li>
        <span class="name">{{ name }}</span>
        <span class="role">{{ role }}</span>
    </li>
</template>
<script>
    export default {
        props: ['name', 'role']
    }
</script>
<style scoped lang="scss">
    li {
        text-align: left;
        display: flex;
        flex-direction: column;
        font-size: 25px;
        font-size: 2.5rem;
        font-size: min(max(2.5rem, 5vw), 5rem);
        font-size: clamp(2.5rem, 5vw, 5rem);
        border-bottom: 1px solid currentColor;
        padding-bottom: .75em;
        margin-bottom: .75em;

        @media print {
            font-size: 20px;
        }
    }

    .name {
        font-family: "monotype grotesque";
        text-transform: uppercase;
    }

    .role {
        font-size: .75em;
        font-family: "monotype grotesque";
    }
</style>