<template>
    <ul>
        <PersonItem 
            v-for="person in people"
            :key="person.name"
            :name="person.name"
            :role="person.role"
        />
    </ul>
</template>
<script>
    import PersonItem from './PersonItem.vue';

    export default {
        props: ['people'],
        components: {
            PersonItem,
        }

        
    }
</script>
<style scoped>
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
</style>
