<template>
    <div class="page">
        <div class="page__content">
            <FavoritEventsList />
            <FooterSponsors />
        </div>
    </div>
</template>
<script>
    import FavoritEventsList from './FavoritEventsList.vue';
    import FooterSponsors from './FooterSponsors.vue';
    export default {
        components: { 
            FavoritEventsList,
            FooterSponsors },
    };
</script>
