<template>
    <button @click="toggleModal">
        <span v-if="!modalIsOpen">i</span>
        <span v-else>X</span>    
    </button>
    <teleport to="body">
        <transition name="modal"> 
            <div class="dialog" v-show="modalIsOpen" @click.self="toggleModal">
                <transition name="dialog"> 
                    <div class="dialog__container" open v-if="modalIsOpen">
                        <div class="dialog__content">
                            <h2>Les Programmes</h2>
                            <p>Retrouvez ici tous les programmes du soir de nos concerts de la saison 2022-23 vous renseignant sur les œuvres et les artistes concernés. Disponibles une semaine à l’avance, vous pouvez les consulter librement où que vous soyez.</p>


          <h2>Astuces</h2>
          <!-- <ul>
            <li>
              Ajoutez un événement à vos favoris pour y accéder plus facilement
            </li>
            <li>Mettez un concert à votre calendrier pour ne pas le rater</li>
            <li>
              Imprimez votre programme depuis votre ordinateur si vous préférez
              une version papier.
            </li>
            <li>
              Ajoutez une icône à votre téléphone pour accéder plus facilement à
              ce site.
            </li>
          </ul> -->
          <ul class="tips-list">
            <li class="tips"><img src="@/assets/img/icon-cal.svg" alt="" class="tips__img"><span class="tips__text">Ajoutez le concert à votre calendrier personnel.</span></li>
            <li class="tips"><img src="@/assets/img/icon-favorite-heart.svg" alt="" class="tips__img"><span class="tips__text">Enregistrez vos concerts favoris afin de les retrouver facilement lors de votre prochaine visite.</span></li>
            <li class="tips"><img src="@/assets/img/icon-print.svg" alt="" class="tips__img"><span class="tips__text">Imprimez le programme si vous préférez le lire sur papier.</span></li>
          </ul>
          <p>
            <strong
              >Ajoutez une icône à votre téléphone pour accéder directement au programme :</strong
            >
          </p>
          <ul>
            <li>
              Sur iPhone /Safari : cliquez sur l’icône centrale en bas de votre
              écran (carré bleu avec une flèche vers le haut), puis sur “Sur
              l’écran d’accueil”.
            </li>
            <li>
              Sur Android/Chrome : cliquez sur les 3 points en haut à droite de
              l’écran, puis sur “Ajouter à l’écran d’accueil”.
            </li>
          </ul>
          <p>
            Une nouvelle icône “OSR” figurera sur l’écran d’accueil de votre
            smartphone.
          </p>
          <div class="btn-container">
            <a
              href="https://www.osr.ch"
              class="btn"
              title="Acceuil OSR.CH"
              target="_blank"
              >Accueil OSR.CH</a
            >
          </div>
                        </div>
                    </div>
                </transition> 
            </div>
        </transition>
    </teleport>
</template>
<script>
    export default {
        computed: {
            modalIsOpen(){
                return this.$store.state.modalIsOpen;
            }
        },
        methods: {
            toggleModal() {
                this.$store.dispatch('toggleModal');
            }
        },
        // mounted(){
        //     console.log(this.modalIsOpen)
        // }
    }
</script>
<style scoped lang="scss">

    h2 {
        font-family: "monotype grotesque";
        width: 100%;
        font-size: 25px;
        font-size: 2.5rem;
        margin: 2em 0;
        text-transform: uppercase;
        text-align: center;
    }

    a {
        color: inherit;
    }

    ul, li {
        font-size: 1.8rem;
    }

    li {
        margin-bottom: .25em;
    }

    strong {
        font-family: 'monotype grotesque';
    }

    button {
        position: absolute;
        top: calc(50% - 15px);
        right: 20px;
        padding: 0;
        color: currentColor;
        text-decoration: none;
        font-size: 20px;
        line-height: 0.5;
        background-color: transparent;
        border: 1px;
        width: 30px;
        height: 30px;
        max-width: 30px;
        max-height: 30px;
        border-radius: 50%;
        border: 1px solid black;
        transition: all .3s ease-in-out;

        &:hover {
            background-color: black;
            color: #FF726D;
        }
        span{
            line-height: 1.5;
        }
    }

    .dialog {
        position: fixed;
        top: 0;
        left: 0;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(black, .5);
        font-family: 'monotype grotesque';
        
        z-index: 10;
    }

    .dialog__container {
        max-width: 750px;
        max-height: calc(100vh - 190px);
        padding: 0 30px 30px 30px;
        border: none;
        background-color: #FF726D;
        border-radius: 5px;
        overflow: scroll;
    }

    .modal-enter-from,
    .modal-leave-to  {
        opacity: 0;
    }

    .modal-enter-active {
        transition: all .5s ease-in-out;
    }

    .modal-leave-active {
        transition: all .25s ease-in-out;
    }

    .modal-enter-to,
    .modal-leave-from {
        opacity: 1;
    }

    .dialog-enter-from {
        transform: scale(1.05) translate3d(0,25px,0);
    }

    .dialog-leave-to {
        transform: scale(.95) translate3d(0,-25px,0);
    }

    .dialog-enter-active {
        transition: all .5s ease-in-out;
    }

    .dialog-leave-active {
        transition: all .25s ease-out;
    }

    .dialog-enter-to,
    .dialog-leave-from {
        transform: scale(1) translate3d(0,0,0);
    }

    .btn-container {
        display: flex;
        justify-content: center;
    }
    
    .btn {
        display: inline-flex;
        align-items: center;
        color: black;
        font-family: "monotype grotesque";
        text-decoration: none;
        padding: 0.25em 3em;
        //margin: 2em 0;
        margin-top: 1em;
        font-size: 15px;
        font-size: 1.5rem;
        font-size: min(max(1.5rem, 3vw), 3rem);
        font-size: clamp(1.5em, 3vw, 3rem);
        border: 1px solid black;
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

        &:hover {
            color: rgba(255, 114, 109, 1);
            background-color: black;
        }
    }

        .tips-list {
      margin-bottom: 2em;
    }

    .tips {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
    }

    .tips__img {
      flex: 0 0 60px;
      max-width: 60px;
    }

    .tips__text {
      margin-left: 1em;
    }

</style>
