<template>
    <div class="single-ctas">
        <div>
            <button @click="toggleFavorit(Number(selectedEvent.id))" class="cta cta--add-to-fav">
                <span v-if="!isFav" class="cta__label">Ajouter aux favoris</span>
                <span v-else class="cta__label">Je suis dans vos favoris</span>
                <svg class="icon icon--fav" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5">
                    <polygon class="icon__star" :class="{'icon--fill' : isFav}" points="10.66 2.85 12.68 8.07 18.17 8.41 13.93 11.98 15.3 17.42 10.66 14.4 6.02 17.42 7.4 11.98 3.15 8.41 8.64 8.07 10.66 2.85"/>
                    <rect class="icon__square" x="0.25" y="0.25" width="21" height="21"/>
                </svg>
            </button>
            <!-- <button class="cta cta--add-to-calendar">
                Ajouter au calendrier
                <img class="icon" src="../assets/img/icon-cal.svg" alt="">
            </button> -->
            <AddToCalendarNew
                class="button cta cta--add-to-calendar"
                :start="selectedEvent.date"
                :time="selectedEvent.time"
                :title="selectedEvent.title"
                :location="selectedEvent.where"
                :description="selectedEvent.content.description"
            />
            <button @click="printThisPage" class="cta cta--print">
                <span class="cta__label">Imprimer la page</span>
                <img class="icon" src="../assets/img/icon-print.svg" alt="">
            </button>
        </div>
    </div>
</template>
<script>
    import AddToCalendarNew from './AddToCalendarNew.vue';

    export default {
        components: {
            AddToCalendarNew
        },
        props: ['slug'],
        computed: {
            isFav(){
                return this.$store.state.favorites.includes(Number(this.selectedEvent.id));
            },
            selectedEvent(){
                //return this.$store.state.events.find(event => event.id === this.$route.params.eventId)
                // return this.$store.state.events.find(event => event.id == this.id)
                return this.$store.state.events.find(event => event.slug == this.slug)
            }
        },
        methods: {
            printThisPage(){
                window.print();
            },
            toggleFavorit(id){
                // this.isFavorit = !this.isFavorit;
                //console.log('togglew to fav from event card');
                this.$store.commit('toggleToFavorites', {
                    concert: id
                });
                //console.log(this.$store.state.favorites.includes(this.id))
            }
        },
        mounted(){
            if(window.addeventatc != undefined){
                window.addeventatc.refresh();
            }
        }
    }
</script>
<style scoped lang="scss">
    .cta--add-to-calendar:deep(.icon)  {
        width: 25px;
        height: 25px;
        flex: 0 0 25px;
        min-width: 25px;
    }

   .cta__label,
   .cta--add-to-calendar:deep(.cta__label)  {
       font-size: 12px;
       font-family: 'monotype grotesque';
       font-weight: 400!important;
   }

    .icon {
        width: 25px;
        height: 25px;
        flex: 0 0 25px;
        min-width: 25px;
    }
    .icon__star, .icon__square {
        fill: none;
        stroke: #000;
        stroke-width: 0.5px;
    }

    .icon__star {
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .icon__square {
        stroke-miterlimit: 10;
    }

    .icon--fill {
        fill: #000;
    }

    .single-ctas {
        // position: sticky;
        // top: 100px;
        // left: 0;
        width: 100%;
        height: 50px;
        font-size: 1.2rem;
        background-color: #ebebeb;
        z-index: 100;

        >div {
            display: flex;
            width: 100%;
            height: 100%;
            max-width: 1200px;
            margin: 0 auto;
        }

        button,
        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 calc(100%/3);
            padding: 0 10px;
            border: 0;
            //border-left: 1px solid #979797;
            border-right: 1px solid #979797;
            cursor: pointer;

            &:first-child {
                border-left: 1px solid #979797;
            }

            .icon {
                margin-left: 30px;
            }

            &:deep(.icon) {
                margin-left: 30px;
            }
        }

        @media print {
            height: 0;
            display: none;
        }
    }

    .touch .cta--print {
        display: none;
    }

</style>