<template>
    <div class="ad">
        <!-- <div :style="{'background-image': 'url(' + img + ')'}"  class="ad__bg"> -->
        <div class="ad__bg">
            <img :src="img">
        </div>
        <a :href="url" title="" class="ad-img-box" v-if="url" target="_blank">
            <img :src="img" alt="">
        </a>
        <div v-else class="ad-img-box">
            <img :src="img" alt="">
        </div>
        
    </div>
</template>
<script>
    export default {
        props: [
            'img',
            'url'
        ],
        // mounted(){
        //     console.log(this.url)
        // }
    };
</script>
<style scoped lang="scss">
    .ad {
        position: relative;
        //overflow: hidden;

        .ad-img-box { 
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            margin: 0 auto;

            img {
                display: block;
                width: 100%;
                max-width: 400px;
                align-self: center;

                @media print {
                    max-width: 2cm;
                }
            }
        }

        &__bg {
            position: absolute;
            top: 0;
            left: calc(50% - 50vw);
            width: 100vw;
            height: 100%;
            background-size: cover;
            background-position: center;
            overflow: hidden;
            z-index: 5;

            img {
                position: absolute;
                top: -20px;
                left: -20px;
                object-fit: cover;
                display: block;
                width: calc(100% + 40px);
                height: calc(100% + 40px);
                max-width: unset;
                filter: blur(10px);
            }

            @media print {
                display: none;
            }
        }
    }
</style>