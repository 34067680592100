<template>
    <li class="event-card" :class="cardClass" ref="{{ id }}">
        <div class="event-card__top">
            <span class="event-card__serie-type" v-for="serie in series" :key="serie">{{ serie }}</span>
            <h2 class="event-card__title">{{ title }}</h2>
            <ul>
                <li v-for="person in people" :key="person">{{ person.name }}</li>
            </ul>
        </div>
        <div class="event-card__info">
            <div>
                <div>
                    <span class="event-card__day">{{ day }}</span>&nbsp;&nbsp;
                    <span class="event-card__date">{{ date }}</span>
                </div>
                <div>
                    <span class="event-card__time">{{ time }}</span> — 
                    <span class="event-card__where">{{ where }}</span>
                </div>
            </div>
        </div>
        <div class="event-card__ctas">

            <div>
                <button class="event-card__cta event-card__cta--icon" :class="{'is-fav' : isFav}" @click="toggleFavorit(id);">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <polygon points="50 6.08 37 37 4 40 29 61 21.13 93.26 50 76.3 78 94 71 62 96 40 63 37 50 6.08" />
                        <path
                            d="M79,95.84a1.27,1.27,0,0,1-.7-.21L50,77.65l-28.26,18a1.31,1.31,0,0,1-2-1.43l8.37-32.43L2.3,40.45a1.31,1.31,0,0,1,.76-2.32l33.43-2.06L48.78,4.91a1.37,1.37,0,0,1,2.44,0L63.51,36.07l33.43,2.06a1.31,1.31,0,0,1,.76,2.32L71.86,61.77,80.23,94.2a1.3,1.3,0,0,1-.5,1.39A1.36,1.36,0,0,1,79,95.84ZM50,74.78a1.27,1.27,0,0,1,.7.21L76.86,91.64l-7.75-30a1.28,1.28,0,0,1,.44-1.33L93.46,40.54l-30.95-1.9a1.33,1.33,0,0,1-1.14-.83L50,9,38.62,37.81a1.31,1.31,0,0,1-1.13.83L6.54,40.54,30.45,60.28a1.28,1.28,0,0,1,.44,1.33l-7.75,30L49.3,75A1.25,1.25,0,0,1,50,74.78Z"
                        />
                    </svg> -->
                    <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" width="89.23" height="76.74" viewBox="0 0 89.23 76.74">
                        <path class="heart--border" d="M44.62,76.74c-2.13,0-4.15-.8-5.7-2.24L8.49,46.1C3.1,41.07,0,33.95,0,26.56v-.98C0,13.02,8.99,2.41,21.37,.34c8-1.36,16.54,1.38,22.3,7.15l.9,.9,.79-.86C51.38,1.66,59.78-1,67.86,.34c12.38,2.06,21.37,12.68,21.37,25.24v.98c0,7.4-3.1,14.52-8.5,19.55l-30.42,28.4c-1.55,1.45-3.58,2.24-5.7,2.24ZM25.49,3.04c-1.21,0-2.42,.1-3.61,.3C10.96,5.17,3.05,14.52,3.05,25.58v.98c0,6.55,2.74,12.86,7.53,17.32l30.42,28.4c1.97,1.84,5.28,1.84,7.25,0l30.42-28.4c4.79-4.45,7.53-10.76,7.53-17.32v-.98c0-11.06-7.92-20.41-18.83-22.23-7.14-1.19-14.54,1.17-19.81,6.31l-1.81,1.96c-.28,.3-.67,.48-1.09,.49-.42,0-.81-.15-1.11-.44l-2.02-2.02c-4.21-4.22-10.12-6.6-16.03-6.6Z"/>
                        <path class="heart--fill" d="M1.52,26.56v-.98C1.52,13.82,10.03,3.78,21.62,1.85c7.52-1.28,15.49,1.22,20.97,6.72l2.02,2.02,1.87-2.02c5.64-5.5,13.45-8,21.13-6.72,11.6,1.93,20.1,11.97,20.1,23.73v.98c0,6.99-2.9,13.67-8.01,18.43l-30.42,28.4c-1.26,1.18-2.93,1.83-4.66,1.83s-3.4-.66-4.66-1.83L9.53,44.99C4.42,40.23,1.52,33.54,1.52,26.56h0Z"/>
                    </svg>
                </button>
                <!-- <button class="event-card__cta  event-card__cta--icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <path 
                            d="M81.51,1.5h-63a17,17,0,0,0-17,17v63a17,17,0,0,0,17,17h63a17,17,0,0,0,17-17v-63A17,17,0,0,0,81.51,1.5ZM4.12,39.33h17.7v27H4.12Zm20.32,0H48.69v27H24.44ZM48.69,68.91v27H24.44v-27Zm2.62,0H75.56v27H51.31Zm0-2.62v-27H75.56v27Zm26.87-27h17.7v27H78.18ZM18.49,4.12h63A14.39,14.39,0,0,1,95.88,18.49V36.71H4.12V18.49A14.39,14.39,0,0,1,18.49,4.12ZM4.12,81.51V68.91h17.7v27H18.49A14.39,14.39,0,0,1,4.12,81.51ZM81.51,95.88H78.18v-27h17.7v12.6A14.39,14.39,0,0,1,81.51,95.88Z"
                        />
                        <path
                            d="M67.75,22.92l-2.46,1.42A7.82,7.82,0,0,0,62,27.53a10.54,10.54,0,0,0-1,4.12H73.57V29.47h-10A4.81,4.81,0,0,1,65.17,27a11.52,11.52,0,0,1,2-1.32l1.76-1a15.1,15.1,0,0,0,3.21-2.2,5.17,5.17,0,0,0,1.55-3.76A5.68,5.68,0,0,0,72.21,15a5.74,5.74,0,0,0-4.61-1.71q-3.78,0-5.28,2.67a8.18,8.18,0,0,0-.88,3.9h2.34a6.28,6.28,0,0,1,.54-2.67,3.33,3.33,0,0,1,3.27-1.8,3.49,3.49,0,0,1,2.56,1,3.59,3.59,0,0,1-.09,5A11.48,11.48,0,0,1,67.75,22.92Z"
                        />
                        <path d="M81.57,31.65H84V13.39H82.21a4,4,0,0,1-1.47,2.7,9.38,9.38,0,0,1-3.48.81v1.77h4.31Z" />
                    </svg>
                </button> -->
                <AddToCalendarNew
                    class="event-card__cta event-card__cta--icon"
                    :start="date"
                    :time="time"
                    :title="title"
                    :location="where"
                    :description="description"
                />
                <!-- <a href="/">Details</a> -->
                <router-link :to="'/' + slug" class="event-card__cta more-details">Détails</router-link>
            </div>
        </div>
    </li>
    <!-- <AddToCalendarNew
        :start="date"
        :time="time"
        :title="title"
        :location="where"
    /> -->
</template>

<script>
import AddToCalendarNew from './AddToCalendarNew.vue';

export default{
    components: {
        AddToCalendarNew
    },
    props: [
        'id',
        'series',
        'title',
        'people',
        'day',
        'date',
        'time',
        'where',
        'bgColor',
        'txtColor',
        'description',
        'slug'
    ],
    data(){
        return {
            // isFavorit: false
        }
    },
    computed: {
        cardClass(){
            let color =""
            if(this.bgColor == 'bg-black'){
                color = 'pink'
            } else{
                color= 'black'
            }
            return 'event-card--txt-' + color + ' ' + 'event-card--' + this.bgColor + ' ' + 'event-card--' + this.id
        },
        isFav(){
            return this.$store.state.favorites.includes(this.id);
        },
        currentSerie(){
            return this.$route.query.serie != undefined ? this.$route.query.serie : 'all'
        }
    },
    methods: {
        toggleFavorit(id){
            this.$store.commit('toggleToFavorites', {
                concert: id
            });
        }
    },
    mounted(){
        //console.log(this.id);

    }
}
</script>

<style scoped lang="scss">
    $yellow: rgb(255,255,0);
    $purple: rgb(86,40,133);
    $darkBlue: rgb(0,110,255);
    $lightBlue: rgb(0,200,255);
    $green: rgb(0,100,55);
    $oldpink: rgb(255,195,255);
    $pink: #FF726D;
    $beige: #EDEADB;
    $black: #000000;
    $gold: #A28C59;


    
    .event-card {
        border-bottom: 2px solid white;
        // padding: 0 30px;
    }

    .event-card__top {
        // font-size: 35px;
        // font-size: 3.5rem;
        // font-size: min(max(3.5rem, 5vw), 9rem);
        // font-size: clamp(3.5rem, 6.5vw, 9rem);
        font-size: 25px;
        font-size: 2.5rem;
        font-size: min(max(2.5rem, 5vw), 9rem);
        font-size: clamp(2.5rem, 5vw, 9rem);
        // padding: 50px 30px;
        max-width: 1200px;
        padding: 1.35em 20px;
        margin: 0 auto;
        text-align: center;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: .72em;
            font-family: 'monotype grotesque';
            text-transform: uppercase;
            text-align: center;
        }
    }

    .event-card__serie-type {
        display: block;
        font-family: 'monotype grotesque';
        font-size: .55em;
        //font-size: 2rem;
    }
    
    .event-card__title {
        font-family: 'monotype grotesque';
        //font-size: 3.5rem;
        text-transform: uppercase;
        margin: 0.5em 0;
        // font-size: 35px;
        // font-size: 3.5rem;
        // font-size: min(max(3.5rem, 5vw), 6.5rem);
        // font-size: clamp(3.5rem, 5vw, 6.5rem);
    }

    .event-card__info {
        text-align: left;
        border-top: 1px solid currentColor;
        border-bottom: 1px solid currentColor;
        font-size: 20px;
        font-size: 2rem;
        font-size: min(max(1.4rem, 1.5vw), 2rem);
        font-size: clamp(1.4rem, 1.5vw, 2rem);

        >div {
            max-width: 1200px;
            padding: 20px;
            margin: 0 auto;
        }
    }

    .event-card--txt-purple {
        color: $purple;
    }
    .event-card--txt-yellow {
        color: $yellow;
    }
    .event-card--txt-pink {
        color: $pink;
        .event-card__cta.more-details{
            color: black;
        }
    }
    .event-card--txt-light-blue {
        color: $lightBlue;
    }
    .event-card--txt-dark-blue {
        color: $darkBlue;
    }
    .event-card--txt-green {
        color: $green;
    }
    .event-card--txt-black {
        color: black;
    }
    .event-card--txt-white {
        color: white;
    }

    .event-card--bg-purple {
        background-color: $purple;
    }
    .event-card--bg-yellow {
        background-color: $yellow;
    }
    .event-card--bg-pink {
        background-color: $pink;
    }
    .event-card--bg-light-blue {
        background-color: $lightBlue;
    }
    .event-card--bg-dark-blue {
        background-color: $darkBlue;
    }
    .event-card--bg-green {
        background-color: $green;
    }
    .event-card--bg-black {
        background-color: black;
    }
    .event-card--bg-white {
        background-color: white;
    }
    .event-card--bg-beige{
        background-color: $beige
    }
    .event-card--bg-gold{
        background-color: $gold;
    }
    .event-card__ctas {
        color: currentColor;
        display: flex;
        justify-content: center;
        font-family: 'monotype grotesque';

        > div {
            display: flex;
            width: 100%;
            max-width: 1200px;
            padding: 20px;
        }
    }

    .event-card__cta {
        height: 40px;
        height: 4rem;
        height: min(max(4rem, 5vw), 8rem);
        height: clamp(4rem, 5vw, 8rem);
        //height: 40px;
        color: currentColor; 
        margin-right: 10px;
        border: 1px solid currentColor;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-size: 2rem;
        font-size: min(max(2rem, 5vw), 4rem);
        font-size: clamp(2rem, 5vw, 4rem);
        background-color:#FF726D ;

        &:last-child {
            margin-right: 0;
        }

        &--icon {
            width: 40px;
            width: 4rem;
            width: min(max(4rem, 5vw), 8rem);
            width: clamp(4rem, 5vw, 8rem);
            padding: 0;
            background-color: transparent;
        }

        path {
            fill: currentColor;
            transition: all .3s ease-in-out;
        }

        svg {
            width: 65%;
            overflow: visible;
        }

        // polygon {
        //     transform: scale(1.5) rotate(10deg);
        //     opacity: 0;
        //     fill: currentColor;
        //     transition: all .3s ease-in-out;
        // }

        .heart--border {
            fill: currentColor;
            transition: all .3s ease-in-out;
        }

        .heart--fill {
            transform: scale(1.5) rotate(10deg);
            opacity: 0;
            fill: currentColor;
            transition: all .3s ease-in-out;
        }

        &.is-fav{ 
            .heart--fill {
                transform: scale(1) rotate(0deg);
                opacity: 1;
            }
            .heart--border {
                transform: scale(.5) rotate(-10deg);
                opacity: 0;
            }
        }
    }

    .more-details {
        flex: auto;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;

        .event-card--txt-purple & {
            &:hover {
                background-color: $purple;
            }
            
        }
        .event-card--txt-yellow & {
            &:hover {
                background-color: $yellow;
            }
            
        }
        .event-card--txt-pink & {
            &:hover {
                background-color: $pink;
            }
            
        }
        .event-card--txt-light-blue & {
            &:hover {
                background-color: $lightBlue;
            }
            
        }
        .event-card--txt-dark-blue & {
            &:hover {
                background-color: $darkBlue;
            }
            
        }
        .event-card--txt-green & {
            &:hover {
                background-color: $green;
            }
            
        }
        .event-card--txt-black & {
            &:hover {
                background-color: black;
            }
            
        }
        .event-card--txt-white & {
            &:hover {
                background-color: white;
            }
        }

        .event-card--bg-purple & {
            &:hover {
                color: $purple;
            }
            
        }
        .event-card--bg-yellow & {
            &:hover {
                color: $yellow;
            }
            
        }
        .event-card--bg-pink & {
            &:hover {
                color: $pink;
            }
            
        }
        .event-card--bg-light-blue & {
            &:hover {
                color: $lightBlue;
            }
            
        }
        .event-card--bg-dark-blue & {
            &:hover {
                color: $darkBlue;
            }
            
        }
        .event-card--bg-green & {
            &:hover {
                color: $green;
            }
            
        }
        .event-card--bg-black & {
            &:hover {
                color: black;
            }
            
        }
        .event-card--bg-white & {
            &:hover {
                color: white;
            }
        }
    }



</style>
