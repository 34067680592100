<template>
    <ul>
        <EventCard 
            v-for="event in favList"
            :id="event.id"
            :key="event.id"
            :series="event.series"
            :title="event.title"
            :people="event.content.people"
            :day="event.day"
            :date="event.date"
            :time="event.time"
            :where="event.where"
            :bgColor="event.bgColor"
            :txtColor="event.txtColor"
            :description="event.content.program"
            :slug="event.slug"
        />
    </ul>
</template>
<script>
import EventCard from './EventCard.vue'
export default {
    components: {
        EventCard,
    },
    data(){
        return {}
    },
    computed: {
        favList(){
            return this.$store.state.events.filter(event => this.$store.state.favorites.includes(event.id))
            //return this.$store.state.events;
        }
    },
    mounted(){
        if(window.addeventatc != undefined){
            window.addeventatc.refresh();
        }
    }
}
</script>
<style scoped>
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
</style>
