<template>
    <li class="dropdown" :class="dropDownClass">
        <button @click="toggle">{{ title }} <span></span></button>
        <div class="dropdown-content">
            <div v-if="type == 'text'">
                <div v-html="content"></div>
                <!-- <a :href="adUrl" :if="adImg">
                    <img :src="adImg">
                </a> -->
                <AdBox 
                    :img="adImg"
                    :url="adUrl"
                />
            </div>
            <div v-else>
                <img :src="image" class="dropdown--person__img">
                <h2 class="dropdown--person__name">{{ title }}</h2>
                <span class="dropdown--person__role">{{thisPerson.role}}</span>
                <div class="dropdown--person__bio" v-html="content"></div>
                <AdBox 
                    :img="adImg"
                    :url="adUrl"
                />
            </div>
        </div>
    </li>
</template>
<script>
    import AdBox from './AdBox.vue';
    import gsap from 'gsap';
    import {Power3} from 'gsap';

    export default {
        components: {
            AdBox
        },
        props: [
            'title', 
            'content', 
            'adImg', 
            'adUrl', 
            'type',
            'image',
            'personRole',
            'people'
            ],
        data(){
            return {
                isClosed: true,
            }
        },
        computed: {
            dropDownClass(){
                return [{'dropdown--open' : !this.isClosed}]
            },
            thisPerson(){
                return this.people.find(person => person.name == this.title)
            }
        },
        methods: {
            toggle(e){
                const thisContent = e.target.nextSibling;
                const thisContentHeight = thisContent.querySelector('div').offsetHeight;
                if(this.isClosed){
                    gsap.to(thisContent, 1, {
                        height: thisContentHeight + 'px',
                        ease: Power3.easeInOut,
                        onStart: ()=> {
                            this.isClosed = false
                        },
                        onComplete: () => {
                            gsap.set(thisContent, {height: 'auto'})
                        }
                    })
                } else {
                    gsap.to(thisContent, 1, {
                        height: 0 + 'px',
                        ease: Power3.easeInOut,
                        onComplete: this.isClosed = true
                    })
                }
                
                
            }
        }
    };
</script>
<style scoped lang="scss">
    .dropdown {
        width: 100%;
        text-align: center;

        &--open {
            button span {
                &:after {
                    transform: rotate(0deg);
                }
            }
        }

        &:deep(p:first-child) {
            margin-top: 0;
        }

        &:deep(h3, h4) {
            font-family: "monotype grotesque";
            text-transform: uppercase;
            font-size: 25px;
            font-size: 2.5rem;
            font-size: min(max(2.5rem, 5vw), 5rem);
            font-size: clamp(2.5rem, 5vw, 5rem);
            margin-bottom: .25em;
            text-align: center;
        }
        
        &:deep(p, ol, ul) {
            font-size: 1.8rem;
            text-align: left;

            @media print {
                font-size: 1.4rem;
            }
        }
        

        // &::v-deep {
        //     h3, h4 {
        //         font-family: "displace-serif-regular";
        //         text-transform: uppercase;
        //         font-size: 25px;
        //         font-size: 2.5rem;
        //         font-size: min(max(2.5rem, 5vw), 5rem);
        //         font-size: clamp(2.5rem, 5vw, 5rem);
        //         margin-bottom: .25em;
        //         text-align: center;
        //     }

        //     p, ol, ul {
        //         font-size: 1.8rem;
        //         text-align: left;

        //         @media print {
        //             font-size: 1.4rem;
        //         }
        //     }
        // }

    }

    .dropdown-content {
        height: 0;
        overflow: hidden;

        > div {
            padding: 20px 0;
        }

        // &::v-deep h4 {
        //     font-family: "displace-serif-regular";
        //     text-transform: uppercase;
        //     font-size: 25px;
        //     font-size: 2.5rem;
        //     font-size: min(max(2.5rem, 5vw), 5rem);
        //     font-size: clamp(2.5rem, 5vw, 5rem);
        //     margin-bottom: .25em;

        //     + p {
        //         text-align: center;
        //         font-family: "gt-eesti-bold";
        //         font-size: 20px;
        //         font-size: 2rem;
        //         font-size: min(max(1.4rem, 1.5vw), 2rem);
        //         font-size: clamp(1.4rem, 1.5vw, 2rem);
        //     }

        //     @media print {
        //         font-size: 20px;
        //     }
        // }

        @media print {
            height: auto;
            overflow: visible;

            img {
                max-width: 2cm;
            }
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none;
        color: inherit;
        font-size: 2rem;
        padding: .5em 0;
        border-top: 1px solid currentColor;
        background-color: transparent;

        span {
            position: relative;
            display: block;
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            border: 1px solid currentColor;
            pointer-events: none;

            &:before,
            &:after {
                content: "";
                display: block;
                width: 12px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: calc(50% - 6px);
                background-color: currentColor;
            }

            &:after {
                transform: translate3d(0, 0, 0) rotate(90deg);
                transition: all .3s ease-in-out;
            }

            @media print {
                display: none;
            }
        }
    }

    .dropdown--person__img {
        max-width: 750px;
        width: 100%;
        margin: 0 auto;
    }

    .dropdown--person__name {
        font-family: "monotype grotesque";
        text-transform: uppercase;
        font-size: 25px;
        font-size: 2.5rem;
        font-size: min(max(2.5rem, 5vw), 5rem);
        font-size: clamp(2.5rem, 5vw, 5rem);
        margin-bottom: .25em;

        @media print {
            font-size: 20px;
        }
    }

    .dropdown--person__role {
        font-family: "monotype grotesque";
        font-size: 20px;
        font-size: 2rem;
        font-size: min(max(1.6rem, 1.5vw), 2.5rem);
        font-size: clamp(1.6rem, 1.5vw, 2.5rem);
    }

    .dropdown--person__bio {
        margin-top: 40px;
    }

    


</style>
