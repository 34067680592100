<template>
    <header>
        <div>
            <span class="current-page-name">{{ $store.state.currentPage }}</span>
            <transition mode="out-in"> 
                
                <button @click="$router.go(-1)" v-if="!isHome" class="back">←</button>
            </transition>
            <InfoModal />
        </div>
    </header>
</template>
<script>
    import InfoModal from './InfoModal.vue';

    export default {
        components: {
            InfoModal
        },
        computed: {
            isHome() {
                return this.$route.name === 'home'
            },
            // previousPage(){
            //     return this.$store.previousPage;
            // }
        },
        // mounted() {
        //     console.log(this.previousPage);
        // },
        
    };
</script>
<style scoped lang="scss">
    header {
        display: flex;
        align-items: flex-end;
        position: fixed;
        top: 0;
        left: 0;
        //height: 100px;
        font-family: "monotype grotesque";
        background-color: #FF726D;
        color: black;
        width: 100%;
        font-size: 25px;
        font-size: 2.5rem;
        text-transform: uppercase;
        z-index: 100;
        border-bottom: solid 1px black;

        >div {
            position: relative;
            width: 100%;
            max-width: 1200px;
            padding: 0 20px;
            margin: 25px auto;
            //background-color: red;
        }

        @media print {
            height: 0;
            display: none;
        }
    }
    .back {
        position: absolute;
        top: 0;
        left: 20px;
        color: currentColor;
        text-decoration: none;
        font-size: 40px;
        line-height:29px;
        background-color: transparent;
        border: none;
        padding-left: 0;
        //transition: all 10s ease-in-out;
    }

    .current-page-name {
        display: block;
        padding: 0 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
        //border: 1px solid #000000;
    }

    .v-enter-active {
        transition: all .5s ease-in-out;
    }
    .v-leave-active {
        transition: all .5s ease-in-out;
    }
    .v-enter-from {
        opacity: 0;
        transform: translate3d(10px, 0, 0);
    }

    .v-leave-to{
        opacity: 0;
        transform: translate3d(-10px, 0, 0);
    }


</style>
