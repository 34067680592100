<template>
    <div class="the-program">
        <div>
            <h2>Le programme</h2> 
        </div>
        <PeopleList :people="peopleList"/>
        <div>
            <div v-html="program" class="prog"></div>
            <div v-html="description" class="desc"></div>
        </div>
    </div>
    <AdBox 
        v-if="ad"
        :img="ad.img"
        :url="ad.url"
    />
</template>
<script>
    import PeopleList from './PeopleList.vue';
    import AdBox from './AdBox.vue';

    export default {
        props: [
            'peopleList',
            'program',
            'description',
            'ad'
        ],
        components: {
            PeopleList,
            AdBox
        },
    }
</script>

<style lang="scss">
    .the-program {
        max-width: 1200px;
        padding: 1.35em 20px;
        margin: 0 auto;
        border-bottom: 1px solid currentColor;

        h2 {
            font-family: "monotype grotesque";
            text-transform: uppercase;
            font-size: 35px;
            font-size: 3.5rem;
            font-size: min(max(3.5rem, 6.5vw), 9rem);
            font-size: clamp(3.5rem, 6.5vw, 9rem);

            @media print {
                font-size: 30px;
                margin: 0 .2em;
            }
            
        }
    }

    .prog {
        border-bottom: 1px solid currentColor;
        font-size: 20px;
        font-size: 2rem;
        font-size: min(max(2rem, 4vw), 5rem);
        font-size: clamp(2rem, 4vw, 5rem);
        text-align: left;

        .h4, h4 {
            font-family: "monotype grotesque";
            font-size: 1em;
            margin: 0;

            @media print {
                font-size: 15px;
            }

            + p {
                margin: .25em 0 1em 0;
            }
        }
    }
    
    .desc {

    }

</style>

