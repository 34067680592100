<template>
    <ul>
        <EventCard 
            v-for="event in $store.state.filteredEvents"
            :id="event.id"
            :key="event.id"
            :series="event.series"
            :title="event.title"
            :people="event.content.people"
            :day="event.day"
            :date="event.date"
            :time="event.time"
            :where="event.where"
            :bgColor="event.bgColor"
            :txtColor="event.txtColor"
            :description="event.content.program"
            :slug="event.slug"
        />
    </ul>
</template>
<script>
import EventCard from './EventCard.vue'

export default {
    components: {
        EventCard
    },
    mounted(){
        
        if(window.addeventatc != undefined){
            window.addeventatc.refresh();
        }

        //if(this.$store.state.hasSeenHome == true){
            const page = document.querySelector('.page.programme');
            page.scrollTop = this.$store.state.homeScrollPos;
            // console.log(this.$store.state.filteredEvents.length);
        //}
        
    }
}
</script>
<style scoped>
    ul {
        margin: 50px 0 0 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
    }
</style>
