<template>
    <nav class="footer-nav">
        <router-link :to="'/'" class="footer-nav__item">
            <div class="footer-nav__circle">
                <img src="@/assets/img/logo-2024/osr_logo_noir_sansbaseline.png" alt="OSR">
            </div>
            <span>Accueil</span>
        </router-link>
        <!-- <a href="#" title="A la une" class="footer-nav__item">
            <div class="footer-nav__circle">
                <img src="@/assets/img/icon-music.svg" alt="OSR">
            </div>
            <span>A la une</span>    
        </a> 
        <router-link :to="'/' + featuredItemSlug" class="footer-nav__item">
            <div class="footer-nav__circle">
                <img src="@/assets/img/icon-music.svg" alt="OSR">
            </div>
            <span>A la une</span>  
        </router-link>
        -->
        <router-link :to="'/favoris'" v-if="someFav" class="footer-nav__item">
            <div class="footer-nav__circle">
                <!-- <img src="@/assets/img/icon-favorite.svg" alt="OSR"> -->
                <img src="@/assets/img/icon-favorite-heart.svg" alt="OSR">
            </div>
            <span>Favoris</span>
        </router-link>
    </nav>
</template>
<script>
    export default {
        computed: {
            someFav(){
                return this.$store.state.favorites.length > 0;
            },
            featuredItemSlug(){
                return this.$store.state.featuredEvent.slug;
            }
        },
    };
</script>
<style scoped lang="scss">
    .footer-nav {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
        background-color:#FF726D;
        border-top: 2px solid black; 
        color: yellow;
        z-index: 300;

        >div {
            width: 100%;
        }

        @media print {
            display: none;
        }
    }

    .footer-nav__item {
        color: inherit;
        margin-right: 60px;
        color: black;
        text-decoration: none;
        
        &:last-child {
            margin-right: 0;
        }

    }

    .footer-nav__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color:white;
        color: black;
        border-radius: 50%;

        img {
           width:80%;
           object-fit: contain; 
        }
    }

</style>
