<template>
  <!-- <LoadingScreen />-->  
  <PageHeader />
    <router-view v-slot="slotProps">
      <transition :name="transitionName" mode='in-out'>
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  <FooterNav />
  <transition name="fade">
    <LoadingScreen v-if="$store.state.isLoading" />
    <!-- <LoadingScreen /> -->
  </transition>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import FooterNav from './components/FooterNav.vue'
import LoadingScreen from './components/LoadingScreen.vue'

// import gsap from 'gsap';
// import {Power4} from 'gsap';

export default {
  name: 'App',
  components: {
    PageHeader,
    FooterNav,
    LoadingScreen
  },
  data (){
    return {
      transitionName: 'slide-left'
    }
  },
  computed: {
      nextEvent() {
          return this.$store.state.nextEvent;
      }
  },
  watch: {
    $route(to) {
      // console.log('TO', to)
      // console.log('FROM', from)
      this.transitionName = to.name === 'home' ? 'slide-right' : 'slide-left'
    },
    // nextEvent(newValue, oldValue) {
    //   if(this.$route.name == 'home'){
    //     var nextItemPosition = document.querySelector('.event-card--' + newValue.id).offsetTop;
    //     var scrollElement = document.querySelector('.page.home');
    //     scrollElement.scrollTo(0, nextItemPosition);
    //   }
    // }
  },
  beforeCreate() {

    this.$store.commit('checkConnectionStatus');
    this.$store.dispatch('getAndSetData', {querySerie: this.$route.query.serie});

    if ('ontouchstart' in document.documentElement) {
      document.body.classList.add('touch');
    }
  }
}
</script>

<style lang="scss">

@font-face {
    font-family: 'displace-serif-medium';
    src: url('./assets/font/DisplaceSerif-Medium.eot');
    src: url('./assets/font/DisplaceSerif-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/font/DisplaceSerif-Medium.woff2') format('woff2'),
        url('./assets/font/DisplaceSerif-Medium.woff') format('woff'),
        url('./assets/font/DisplaceSerif-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'displace-serif-regular';
    src: url('./assets/font/DisplaceSerif-Regular.eot');
    src: url('./assets/font/DisplaceSerif-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/font/DisplaceSerif-Regular.woff2') format('woff2'),
        url('./assets/font/DisplaceSerif-Regular.woff') format('woff'),
        url('./assets/font/DisplaceSerif-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gt-eesti-bold';
    src: url('./assets/font/GTEestiDisplay-Bold.eot');
    src: url('./assets/font/GTEestiDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/font/GTEestiDisplay-Bold.woff2') format('woff2'),
        url('./assets/font/GTEestiDisplay-Bold.woff') format('woff'),
        url('./assets/font/GTEestiDisplay-Bold.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gt-eesti-medium';
    src: url('./assets/font/GTEestiDisplay-Medium.eot');
    src: url('./assets/font/GTEestiDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/font/GTEestiDisplay-Medium.woff2') format('woff2'),
        url('./assets/font/GTEestiDisplay-Medium.woff') format('woff'),
        url('./assets/font/GTEestiDisplay-Medium.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gt-eesti-regular';
    src: url('./assets/font/GTEestiDisplay-Regular.eot');
    src: url('./assets/font/GTEestiDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/font/GTEestiDisplay-Regular.woff2') format('woff2'),
        url('./assets/font/GTEestiDisplay-Regular.woff') format('woff'),
        url('./assets/font/GTEestiDisplay-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gt-eesti-light';
    src: url('./assets/font/GTEestiDisplay-Light.eot');
    src: url('./assets/font/GTEestiDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/font/GTEestiDisplay-Light.woff2') format('woff2'),
        url('./assets/font/GTEestiDisplay-Light.woff') format('woff'),
        url('./assets/font/GTEestiDisplay-Light.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

* {
  box-sizing: border-box;
}
@font-face {
  font-family: 'monotype grotesque';
  src: url('./assets/font/monotype-grotesque-roman.woff');
  font-style: normal;
}
@font-face {
  font-family: 'monotype grotesque';
  src: url('./assets/font/monotype-grotesque-italic.woff');
  font-style: italic;
}
#app {
  font-family: 'monotype grotesque', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color:black;
  position: relative;
}

html {
  font-size: 62.5%;
}

body {
    overscroll-behavior-y: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: unset;
}

p, ol, ul {
  font-size: 1.8rem;
  text-align: left;

  @media print {
      font-size: 1.4rem;
  }
}

img {
  max-width: 100%;
}

.page {
  margin-top: 100px;
  position: absolute;
  //height: calc(100vh - 148px);
  height: calc(100vh - env(safe-area-inset-bottom));
  overflow: scroll;
  //scroll-behavior: smooth;
  width: 100%;
  margin-top: 80px;
  background-color: rgb(86,40,133);
  //min-height: 100vh;
  padding-bottom: 0;
  box-shadow: 0px 0px 100px 10px #00000029;

  @media print {
    box-shadow: none;
    height: auto;
    overflow: visible;
    margin-top: 0;
    background-color: white!important;
  }
}

.page__content {
  //scroll-behavior: smooth;
}

.wrapper {
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page + .home {
  z-index: -200;
}

.home + .page {
  z-index: 200;
}

// .page {
//   z-index: 5;
// }



.slide-left-enter-from {
  transform: translate3d(100%, 0, 0);
  z-index: 1;
}

.slide-left-enter-active {
  transition: all .5s ease-in-out;

  // &.is-edge-forward,
  // &.is-edge-back {
  //   transition: all 0s ease-in-out;
  // }
}

.slide-left-enter-to {
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.slide-right-leave-from {
  transform: translate3d(0, 0, 0);
  z-index: 100;
}

.slide-right-leave-active {
  transition: all .5s ease-in-out;
  z-index: 100;

  // &.is-edge-forward,
  // &.is-edge-back {
  //   transition: all 0s ease-in-out;
  // }
}

.slide-right-leave-to {
  transform: translate3d(100%, 0, 0);
}

button {
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease-in-out .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.flickity-viewport.flickity-viewport {
  height: 750px;
}

/* .route-leave-from {
  transform: translateX(0);

}

.route-leave-active {
  transition: all .5s ease-in-out; 
}

.route-leave-to {
  transform: translateX(-100%);

} */

.router-link-active {
  pointer-events: none;
}

.addeventatc.addeventatc {
  border-radius: 0!important;
  background-color: transparent!important;
  box-shadow: unset!important;
  z-index: 10;
}

</style>
