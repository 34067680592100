<template>
    <div title="Add to Calendar" class="addeventatc" data-dropdown-x="left" data-dropdown-y="top">
        <span class="cta__label">Ajouter au calendrier</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="icon">
            <path 
                d="M81.51,1.5h-63a17,17,0,0,0-17,17v63a17,17,0,0,0,17,17h63a17,17,0,0,0,17-17v-63A17,17,0,0,0,81.51,1.5ZM4.12,39.33h17.7v27H4.12Zm20.32,0H48.69v27H24.44ZM48.69,68.91v27H24.44v-27Zm2.62,0H75.56v27H51.31Zm0-2.62v-27H75.56v27Zm26.87-27h17.7v27H78.18ZM18.49,4.12h63A14.39,14.39,0,0,1,95.88,18.49V36.71H4.12V18.49A14.39,14.39,0,0,1,18.49,4.12ZM4.12,81.51V68.91h17.7v27H18.49A14.39,14.39,0,0,1,4.12,81.51ZM81.51,95.88H78.18v-27h17.7v12.6A14.39,14.39,0,0,1,81.51,95.88Z"
            />
            <path
                d="M67.75,22.92l-2.46,1.42A7.82,7.82,0,0,0,62,27.53a10.54,10.54,0,0,0-1,4.12H73.57V29.47h-10A4.81,4.81,0,0,1,65.17,27a11.52,11.52,0,0,1,2-1.32l1.76-1a15.1,15.1,0,0,0,3.21-2.2,5.17,5.17,0,0,0,1.55-3.76A5.68,5.68,0,0,0,72.21,15a5.74,5.74,0,0,0-4.61-1.71q-3.78,0-5.28,2.67a8.18,8.18,0,0,0-.88,3.9h2.34a6.28,6.28,0,0,1,.54-2.67,3.33,3.33,0,0,1,3.27-1.8,3.49,3.49,0,0,1,2.56,1,3.59,3.59,0,0,1-.09,5A11.48,11.48,0,0,1,67.75,22.92Z"
            />
            <path d="M81.57,31.65H84V13.39H82.21a4,4,0,0,1-1.47,2.7,9.38,9.38,0,0,1-3.48.81v1.77h4.31Z" />
        </svg>
        <span class="start">{{ startTime }}</span>
        <span class="timezone">Europe/Paris</span>
        <span class="title">{{ title }}</span>
        <span class="description">{{ description }}</span>
        <span class="location">{{ location }}</span>
    </div>
</template>
<script>
    export default {
        props: [
            'start',
            'time',
            'title',
            'location',
            'description'
        ],
        computed: {
            startTime: function(){
                var newDate = this.start.replace(/\./g,'-');
                var last2 = newDate.slice(-2);
                newDate = newDate.slice(0, -2) + '20' + last2;
                return newDate + ' ' + this.time
            }
        }
    }
</script>
<style lang="scss">
    .event-card__cta.addeventatc {
        color: currentColor!important;
        z-index: 1;
        box-shadow: unset!important;
        border-radius: 0;
        cursor: pointer;

        &:hover {
            background-color: transparent;
        }

        &:active {
            border-width: 1px;
        }

        .cta__label {
            display: none;
        }
    }
    
    .event-card__cta svg {
        width: 70%;
        fill: currentColor;
    }

    // .addeventatc_icon {
    //     display: none;
    // }

    .event-card__cta .atc_node {
        display: none;
    }

    .cta--add-to-calendar .addeventatc_icon {
        display: none;
    }

    .addeventatc_dropdown .copyx.copyx {
        display: none;
    }

    .addeventatc_dropdown {
        top: 100%!important;
        left: 50%!important;
        transform: translateX(-50%)!important;
    }
</style>
