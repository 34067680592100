<template>
    <header class="concert-header">
        <div class="concert-header__top">
            <span class="concert-header__serie-type" v-for="serie in series" :key="serie">{{ serie }}</span>
            <h2 class="concert-header__title">{{ title }}</h2>
            <ul>
                <li v-for="person in people" :key="person">{{ person.name }}</li>
            </ul>
            <!-- <PeopleList :people="people"/> -->
        </div>
    </header>
    <div class="concert-info">
        <div>
            <div>
                <span class="event-card__day">{{ day }}</span>&nbsp;&nbsp;
                <span class="event-card__date">{{ date }}</span>
            </div>
            <div>
                <span class="event-card__time">{{ time }}</span> — 
                <span class="event-card__where">{{ where }}</span>
            </div>
        </div>
    </div>
</template>
<script>
    //import PeopleList from './PeopleList.vue';

    export default {
        components: {
            //PeopleList,
        },
        props: [
            'series', 
            'title', 
            'day', 
            'date', 
            'time', 
            'where',
            'people'
        ]
    }
</script>

<style lang="scss">
    .concert-header {
        border-bottom: 1px solid currentColor;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: .72em;
            font-family: 'monotype grotesque';
            text-transform: uppercase;
            text-align: center;
             
            @media print {
                display: none;
            }
        }
    }

    .concert-header__top {
        max-width: 1200px;
        padding: 1.35em 20px;
        margin: 0 auto;
        font-size: 25px;
        font-size: 2.5rem;
        font-size: min(max(2.5rem, 5vw), 9rem);
        font-size: clamp(2.5rem, 5vw, 9rem);

          @media print {
            padding-top: 0;
            font-size: 20px;
            font-size: 2rem;
        }
    }
    .concert-header__serie-type {
        display: block;
        font-family: 'monotype grotesque';
        font-size: .55em;
    }

    .concert-header__title {
        font-family: 'monotype grotesque';
        text-transform: uppercase;
        margin: .5em 0;
    }

    .concert-info {
        text-align: left;
        border-bottom: 1px solid currentColor;
        font-size: 20px;
        font-size: 2rem;
        font-size: min(max(1.4rem, 1.5vw), 2rem);
        font-size: clamp(1.4rem, 1.5vw, 2rem);

        >div {
            max-width: 1200px;
            padding: 20px;
            margin: 0 auto;
        }
    }

    .event-card__date {
        font-family: 'monotype grotesque';
    }
</style>
