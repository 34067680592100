<template>
    <div class="carousel-wrapper" v-if="hasImage">
        <div>
            <flickity ref="flickity" :options="flickityOptions">
                <div class="carousel-slide" v-for="image in images" :key="image.img">
                    <a :href="image.url" v-if="image.url != ''" target="_blank">
                        <img :src="image.img">
                    </a>
                    <img :src="image.img" v-else>
                    
                </div>
            </flickity>
            
        </div>
    </div>
</template>
<script>
    import Flickity from 'vue-flickity';

    export default {
        components: {
            Flickity
        },
        props: ['images'],
        computed: {
            hasImage(){
                return this.images.length
            }
        },
        data() {
            return {
                imagesLoaded: 0,
                totalImages: this.images.length,
                flickityOptions: {
                    pageDots: true,
                    wrapAround: true,
                    prevNextButtons: false,
                    autoPlay: true,
                    // lazyLoad: true
                    imagesLoaded: true,
                    // setGallerySize: false
                    // any options from Flickity can be used
                }
            }
        },
        // mounted(){
            
            
        // },
        mounted(){
            window.dispatchEvent(new Event('resize'));
        }
        // mounted(){
        //     this.flickityOptions.setGallerySize = true
        // }
    }
</script>

<style scoped lang="scss">
    .carousel-wrapper {
        border-bottom: 1px solid currentColor;

        >div {
            width: 100%;
            max-width: 750px;
            //height: 1000px;
            //padding-top: 100%;
            margin: 0 auto;
        }

        @media print {
            display: none;
        }
        
    }

    img {
        display: block;
        width: 100%;
        //height: 100%;
    }

    .carousel-slide {
        position: relative;
        width: 100%;
        max-width: 750px;
        padding-top: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    :deep(.flickity-page-dots) {
        bottom: 2.5%;
        transform: translateY(50%);

        .dot {
            background-color: white;
            border: 1px solid black;
            width: 12px;
            height: 12px;
            opacity: 1;
            &.is-selected {
                background-color: black;
                border: 1px solid white;
            }
        }
    }
</style>
