<template>
    <div class="video-block">
        <div>
            <a :href="topBtn.url" target="_blank" class="video-block__btn" :title="topBtn.label" v-if="topBtn.url != ''">{{ topBtn.label }}</a>
            <div class="video-container" v-if="hasVid">
                <div>
                    <iframe :src="youtubeSrc"></iframe>
                </div>
            </div>
            <a :href="bottomBtn.url" target="_blank" class="video-block__btn" :title="bottomBtn.label" v-if="bottomBtn.url != ''">{{ bottomBtn.label }}</a>
        </div>
    </div>
</template>
<script>
    export default {
        // data(){
        //     return {
        //         vidUrl
        //     }
        // },
        props: [
            'vidId',
            'topBtn',
            'bottomBtn'
            
        ],
        computed: {
            hasVid(){
                return this.vidId != null
            },
            youtubeSrc(){
                return 'https://www.youtube.com/embed/' + this.vidId
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-block {
        padding: 0 30px;
        background-color: #EBEBEB;

        >div {
            max-width: 1200px;
            margin: 0 auto;
        }

        @media print {
            display: none;
        }
    }

    .video-container {
        font-size: 15px;
        font-size: 1.5rem;
        font-size: min(max(1.5rem,3vw),3rem);
        font-size: clamp(1.5em,3vw,3rem);
        padding: 2em 0;

        > div {
            position: relative;
            //width: 100%;
            height: 0;
            margin: 0 auto;
            padding-top: 56.25%;
        }
        
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            //max-width: 750px;
            height: 100%;
            border: none;
        }
    }

    .video-block__btn {
        display: inline-flex;
        align-items: center;
        color: rgb(86,40,133);
        font-family: 'monotype grotesque';
        text-decoration: none;
        padding: .25em 3em;
        //margin: 2em 0;
        font-size: 15px;
        font-size: 1.5rem;
        font-size: min(max(1.5rem, 3vw), 3rem);
        font-size: clamp(1.5em, 3vw, 3rem);
        border: 1px solid rgb(86,40,133);
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

        &:hover {
            color: white;
            background-color: rgb(86,40,133);
        }

        &:first-child {
            margin-top: 2em;
        }

        &:last-child {
            margin-bottom: 2em;
        }
    }

</style>
