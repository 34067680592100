import 'normalize.css'
import { createApp } from 'vue'
import { createStore } from 'vuex'
import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import App from './App.vue'
import Home from './components/Home.vue'
import Programme from './components/Programme.vue'
import SingleEvent from './components/SingleEvent.vue'
import FavoriteEvents from './components/FavoriteEvents.vue'
import './registerServiceWorker'
import gsap from 'gsap';
import VueGtag from 'vue-gtag';
import moment from 'moment'
// import VueAddtohomescreen from '@owliehq/vue-addtohomescreen';
//import {Power4} from 'gsap';

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes: [
        {name: 'home', path: '/', component: Home},
        {name: 'programme', path: '/programme', component: Programme},
        {name: 'fav', path: '/favoris', component: FavoriteEvents},
        {name: 'single-event', path: '/:eventId', component: SingleEvent},
        // {name: 'serie', path: '/:serie', component: Series}
    ]
});

const store = createStore({
    state(){
        return {
            //dataUrl: 'https://osr-osr20ma32.ideative.io/fr/?json',
            dataUrl: 'https://www.osr.ch/fr/?json',
            connectionStatus: '',
            currentPage: 'Programme',
            currentFilter: 'all',
            currentDate: 'all',
            currentSerie: 'all',
            events: [],
            dates: [],
            series: [],
            filteredEvents: [],
            // serieType: [],
            favorites: [],
            isLoading: true,
            featuredEvent: '',
            modalIsOpen: false,
            introModalIsOpen: true,
            homeScrollPos: 0,
            nextEvent: '',
            nextEventScrollPos: 0,
            hasSeenHome: false,
            previousPage: '/'
        };
    },
    // getters: {
    //     fullListOfEvents: state => {
    //       return state.events;
    //     }
    // },
    // getters: {
    //     isOnline: function () {
    //         if(navigator.onLine){
    //           return true;
    //         } else {
    //           return false;
    //         }
    //     }
    // },
    mutations: {
        toggleToFavorites(state, payload){
            //console.log('togglew to fav from MAIN js');
            if(state.favorites.includes(payload.concert)){
                const index = state.favorites.indexOf(payload.concert);
                state.favorites.splice(index, 1);

                // console.log(router.currentRoute.value.name)
                // console.log(state.favorites.length)

                if(router.currentRoute.value.name == 'fav' && state.favorites.length < 1){
                    router.push({path: '/programme'})
                }
                    
            } else {
                state.favorites.push(payload.concert);
            }
            localStorage.setItem('favEvents', JSON.stringify(state.favorites));
        },
        closeIntroModal(state){
            state.introModalIsOpen = false;
        },
        checkConnectionStatus(state) {	
            if (navigator.onLine){
                state.connectionStatus = 'online';
            } else{
                state.connectionStatus = 'offline';
            }
        },
        setData(state, payload){
            
            state.events.length = 0;
            state.events.push(... payload.data.data.events);
            
            state.series.length = 0;
            let eventsSeries = payload.data.data.events.map(event => event.series).flat(2);
                eventsSeries = [ ...new Set(eventsSeries) ];

            eventsSeries.forEach(serie => {
                //console.log(serie)
                const obj = {};
                //var serieName = serie;
                var serieSlug = serie   .toLowerCase()
                                        .replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
                                        .replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
                                        .replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
                                        .replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
                                        .trim()
                                        .replace('+', 'plus')
                                        .replace(/[^\w\s-]/g, '')
                                        .replace(/[\s_-]+/g, '-')
                                        .replace(/^-+|-+$/g, '');


                obj['name'] = serie;
                obj['slug'] = serieSlug;

                state.series.push(obj)
            });
            
            state.dates.length = 0;
            let dates = payload.data.data.events.map(event => event.date);
                dates = [ ...new Set(dates) ];

            dates.forEach(date => {
                //console.log(date)
                const dateObj = {};
                
                dateObj['id'] = date;
                dateObj['nice'] = moment(date, 'DD/MM/YYYY').locale('fr').format('Do MMMM YYYY');

                //console.log(dateObj['id']);
                //console.log(dateObj['nice']);

                state.dates.push(dateObj);
            });

            //console.log(state.dates);

            state.filteredEvents.push(... state.events);

            //state.featuredEvent = state.events[0];

            //localStorage.setItem('osrEvents', JSON.stringify(payload.data.data.events));


            var today = moment(new Date(), 'DD/MM/YYYY');
            var yesterday = moment().add(-1, 'days');
            var nextEvent = state.events.find(event => moment(event.date, 'DD/MM/YYYY').isAfter(yesterday));

            setTimeout(function(){ 
                if(nextEvent != undefined){
                    state.nextEvent = nextEvent;
                    state.featuredEvent = nextEvent;
                } else {
                    state.nextEvent = state.events.slice(-1)[0];
                    state.featuredEvent = state.events.slice(-1)[0];
                }

                var nextItem = document.querySelector('.event-card--' + store.state.nextEvent.id);
                var nextItemPosition = nextItem != null ? nextItem.offsetTop - 50 : 0;
                state.nextEventScrollPos = nextItemPosition;

                if(router.currentRoute.value.name == 'programme'){
                    var scrollElement = document.querySelector('.page.programme');
                    scrollElement.scrollTo({top: state.nextEventScrollPos});
                    state.hasSeenHome = true
                }
            }, 1);
            
            state.isLoading = false;
        },
        filteringEvents(state, payload){
            console.log('filtering event')
            state.isLoading = true
            
            setTimeout(function(){
                state.currentDate = payload.date;
                state.currentSerie = payload.serie;
                state.filteredEvents.length = 0;
                var page = document.querySelector('.page.programme');

                if(payload.date === 'all' && payload.serie === 'all'){

                    state.filteredEvents.push(... state.events);
                    setTimeout(function(){page.scrollTo({top: state.nextEventScrollPos}); console.log('SCROLLLLLLL') }, 100);
                    
                } else if(payload.date === 'all' && payload.serie !== 'all') {

                    console.log(payload.serie);

                    const filteredList = state.events.filter((event) => event.series.includes(payload.serie));
                    state.filteredEvents.push(... filteredList);
                    page.scrollTo({top: 0});

                } 
                else if(payload.date !== 'all' && payload.serie === 'all') {
                    const filteredList = state.events.filter((event) => event.date === payload.date);
                    state.filteredEvents.push(... filteredList);
                    page.scrollTo({top: 0});
                } 

                else {
                    const filteredList = state.events.filter((event) => event.date === payload.date && event.series.includes(payload.serie));
                    state.filteredEvents.push(... filteredList);
                    page.scrollTo({top: 0});
                }

                state.isLoading = false
            }, 100);
        },
        toggleModal(state){
            state.modalIsOpen = state.modalIsOpen ? false : true;
        }
    },
    actions: {
        scrollToNextEvent(context){
            var today = moment(new Date(), 'DD/MM/YYYY');
            var yesterday = moment().add(-1, 'days');
            var nextEvent = context.state.events.find(event => moment(event.date, 'DD/MM/YYYY').isAfter(yesterday));

            setTimeout(function(){ 
                if(nextEvent != undefined){
                    context.state.nextEvent = nextEvent;
                    context.state.featuredEvent = nextEvent;
                } else {
                    context.state.nextEvent = context.state.events.slice(-1)[0];
                    context.state.featuredEvent = context.state.events.slice(-1)[0];
                }

                var nextItem = document.querySelector('.event-card--' + store.state.nextEvent.id);
                var nextItemPosition = nextItem != null ? nextItem.offsetTop - 50 : 0;
                context.state.nextEventScrollPos = nextItemPosition;

                if(router.currentRoute.value.name == 'programme'){
                    var scrollElement = document.querySelector('.page.programme');
                    scrollElement.scrollTo({top: context.state.nextEventScrollPos});
                    context.state.hasSeenHome = true
                }
            }, 1);
        },
        getAndSetData(context, payload){
            // context.state.commit('setData');
            //console.log(payload.querySerie);
            if(context.state.connectionStatus === 'online'){ // if internet connection, fetch data from porvided API URL
                console.log('online!')
                axios.get(context.state.dataUrl)
                .then(response => {
                  console.log(response)
                  context.commit('setData', {
                      data: response,
                    //   querySerie: payload.querySerie
                  });
                
                if(payload.querySerie){
                    var nameFromSlug = context.state.series.find(serie => serie.slug == payload.querySerie);

                    context.commit('filteringEvents', {
                        date: 'all',
                        serie: nameFromSlug.name
                    });
                }

                })
                .catch(function (error) {
                  // handle error
                  console.log(error);
                })
                .then(function () {
                  // always executed
                });
            } /* else { // Otherwise check if there is a cached version of that data in the localStorage
              const localData = JSON.parse(localStorage.getItem('osrEvents'));
              context.state.events.length = 0;
              context.state.events.push(... localData);
              context.state.filteredEvents.length = 0;
              context.state.filteredEvents.push(... localData);
              context.state.isLoading = false;
            } */

            if(localStorage.getItem('favEvents')) {
                context.state.favorites = JSON.parse(localStorage.getItem('favEvents'));
			}

            
        },
        toggleModal(context){
            context.commit('toggleModal');
        }
    }
});

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueGtag, {
    appName: 'OSR APP',
    pageTrackerScreenviewEnabled: true,
    config: { id: 'G-C8B93803K7' }
}, router);
// app.use(VueAddtohomescreen);

router.isReady().then(function(){
    app.mount('#app');
});

router.beforeEach((to, from, next) => {

    console.log('GLOBAL BEFORE EACH');
    console.log(from);

    if(store.state.modalIsOpen){
        store.dispatch('toggleModal');
    }

    if(from.name === 'home'){
        const pageScrollPos = document.getElementsByClassName('home')[0].scrollTop;
        store.state.homeScrollPos = pageScrollPos;
    }
    
    store.state.previousPage = from;

    next();
    
});

// router.beforeEnter((to, from, next) => {
//     if(from.name === 'home'){
//         const pageScrollPos = document.getElementsByClassName('home')[0].scrollTop;
//         store.state.homeScrollPos = pageScrollPos;
//     }
// });

router.beforeResolve((to, from) => {
    setTimeout(function(){
        if(to.name === 'home' && store.state.hasSeenHome == false){
            var nextItem = document.querySelector('.event-card--' + store.state.nextEvent.id);
            var nextItemPosition = nextItem != null ? nextItem.offsetTop - 50 : 0;
            var scrollElement = document.querySelector('.page.home');
            scrollElement.scrollTo(0, nextItemPosition);
            store.state.hasSeenHome = true
        }
    }, 100);
});

router.afterEach((to, from, next) => {

    //console.log('GLOBAL AFTER EACH');

    gsap.to('.current-page-name', .5, {y: '50%', autoAlpha: 0, onComplete: function(){
        if(to.name === 'single-event'){
            const event = store.state.events.find(event => event.slug == to.params.eventId);
            store.state.currentPage = event.title;
        } else if(to.name === 'fav') {
            store.state.currentPage = 'Favoris'
        } else if(to.name === 'serie') {
            store.state.currentPage = 'Serie'
        } else if(to.name === 'programme') {
            store.state.currentPage = 'Programme'
        } else {
            //console.log('AFTER EACH')

            // if(store.state.hasSeenHome == false){
            //     var nextItemPosition = document.querySelector('.event-card--' + store.state.nextEvent.id).offsetTop;
            //     var scrollElement = document.querySelector('.page.home');
            //     scrollElement.scrollTo({top: nextItemPosition});
            //     store.state.hasSeenHome = true
            // }
            
            store.state.currentPage = 'Programme'
        }

        gsap.to('.current-page-name', .5, {y: 0, autoAlpha: 1})
    }});

    //next();
});


