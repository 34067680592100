<template>
    <div v-if="hasPartners">
        <ul class="partners">
            <PartnerItem 
                v-for="partner in partners" 
                :key="partner"
                :title="partner.title"
                :logo="partner.logo"
                :name="partner.name"
            />
        </ul>
    </div>
</template>
<script>
    import PartnerItem from './PartnerItem.vue';
    export default {
        components: {
            PartnerItem
        },
        props: ['partners'],
        computed: {
            hasPartners(){
                return this.partners.length
            }
        },
    }
</script>
<style scoped lang="scss">
    div {
        display: flex;
        justify-content: center;
        padding: 50px 20px;
        border-bottom: 1px solid currentColor;

        @media print {
            padding: 10px 20px;
        }
    }

    .partners {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1200px;
        margin: 0 -20px;
        padding: 0;
        list-style: none;
    }
</style>
