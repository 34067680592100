<template>
    <div class="page programme">
        <div class="page__content">
            <EventsFilter />
            <EventList />
            <FooterSponsors />
        </div>
    </div>
</template>
<script>
    import EventsFilter from './EventsFilter.vue'
    import EventList from './EventList.vue'
    import FooterSponsors from './FooterSponsors.vue'

    export default {
        components: {
            EventsFilter,
            EventList,
            FooterSponsors
        },
        computed: {
            nextEvent() {
                return this.$store.state.nextEvent;
            }
        },
        mounted() {
            this.$store.dispatch("scrollToNextEvent");
        },
    }
</script>
